export const serviciosPredefinidos = [
    { value: 'AGIP', label: 'AGIP' },
    { value: 'AMEX', label: 'AMEX' },
    { value: 'ANTINA', label: 'ANTINA' },
    { value: 'ARBA', label: 'ARBA' },
    { value: 'AYSA', label: 'AYSA' },
    { value: 'CLARO', label: 'CLARO' },
    { value: 'CTA', label: 'CTA' },
    { value: 'DATOS', label: 'DATOS' },
    { value: 'DEPO-FRANCES', label: 'DEPO-FRANCES' },
    { value: 'DEPOSITO ALQUILER', label: 'DEPOSITO ALQUILER' },
    { value: 'EDENOR', label: 'EDENOR' },
    { value: 'EXPENSAS', label: 'EXPENSAS' },
    { value: 'F102', label: 'F102' },
    { value: 'FLOW', label: 'FLOW' },
    { value: 'FLOW HOME', label: 'FLOW HOME' },
    { value: 'HIPOTECARIO', label: 'HIPOTECARIO' },
    { value: 'MASTER', label: 'MASTER' },
    { value: 'MASTER-GALICIA', label: 'MASTER-GALICIA' },
    { value: 'MONOTRIBUTO', label: 'MONOTRIBUTO' },
    { value: 'MSM', label: 'MSM' },
    { value: 'MUN-3FEB', label: 'MUN-3FEB' },
    { value: 'MUN-LA COSTA', label: 'MUN-LA COSTA' },
    { value: 'MUN-SAN MARTIN', label: 'MUN-SAN MARTIN' },
    { value: 'NARANJA CREDITO', label: 'NARANJA CREDITO' },
    { value: 'NATURGY', label: 'NATURGY' },
    { value: 'OSDE', label: 'OSDE' },
    { value: 'PATAGONIA', label: 'PATAGONIA' },
    { value: 'PERSONAL', label: 'PERSONAL' },
    { value: 'PLAN ARBA', label: 'PLAN ARBA' },
    { value: 'PLAN MUNI', label: 'PLAN MUNI' },
    { value: 'SINDICATOS', label: 'SINDICATOS' },
    { value: 'TELERED', label: 'TELERED' },
    { value: 'VISA', label: 'VISA' },
    { value: 'VISA-CIUDAD', label: 'VISA-CIUDAD' },
    { value: 'VISA-GALICIA', label: 'VISA-GALICIA' },
    { value: 'VISA-PCIA', label: 'VISA-PCIA' },
    { value: 'VISA-SUPERVILLE', label: 'VISA-SUPERVILLE' }
];